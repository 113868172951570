import { Fragment, Suspense, useEffect, useState } from "react"
import "./index.scss"
// import { Route, Routes,Navigate, useSearchParams,useLocation } from 'react-router-dom'
import { Route, Routes, Navigate, useSearchParams, useRoutes, useLocation } from 'react-router-dom'
import routes from "../router"
import { useDispatch } from "react-redux"
import { getPriceMethod, isWeChatMethods, getProductTypeMethods } from "../store/reducer/commonMethodsReducer"
// import {  Mask, SpinLoading } from 'antd-mobile'

function Layout () {
  // const { isPaymentLoading } = useSelector(( state:any ) => { return state.commonMethodsReducer })

  

  const [search,setSearch] = useSearchParams()
  const routerData = useRoutes(routes)
  const dispatch = useDispatch()
  // const  = state
  const location = useLocation()
  // const {  productTyle } = useSelector((state:{[stateTyle:string]:any}) => state.commonMethodsReducer)
  
 
  useEffect(()=>{
    // console.log('layout')
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;  // 切换路由时手动置顶
        window.scrollTo(0,0);
        document.documentElement.scrollTop = 0;
        window.history.scrollRestoration = 'manual' 
      }
    document.title = routerData?.props.match.route.title
    
    if ( search.get('from') === null ) {
      setSearch(`from=10&${location.search.split("?")[1]}`)
    }
    else{
      let originalArr = location.search.split("?")[1].split("&")
      for ( let i = 0; i < location.search.split("?")[1].split("&").length; i++ ) {
        if ( location.search.split("?")[1].split("&")[i].split('=')[0] === 'addType') {
          originalArr.splice(location.search.split("?")[1].split("&").indexOf(location.search.split("?")[1].split("&")[i]), 1)
        }
      }
      switch (location.pathname.split('/')[1]) {
        case 'playlet':
          // console.log('playlet',search.get('addType'))
          if ( search.get('addType') === null ) {
           
            setSearch(`addType=15&${location.search.split("?")[1]}`)
          }
          else if(['15','17','18','19','23','24','25','26','27'].indexOf(search.get('addType') as string) === -1){
            setSearch(`addType=15&${originalArr.join("&")}`)
          }
          break;
        case 'playlet_v2':
          if ( search.get('addType') === null ) {
            setSearch(`addType=16&${location.search.split("?")[1]}`)
          }else if(['16','20','21','22','28','29'].indexOf(search.get('addType') as string) === -1){
            setSearch(`addType=16&${originalArr.join("&")}`)
          }
          break;
      
        default:
          break;
      }
    }
    dispatch(getPriceMethod(search.get('from') as string))
    dispatch(isWeChatMethods())
    dispatch(getProductTypeMethods())
    
  },[search,setSearch,routerData,dispatch,location])
    return (
        <Fragment>
            <Routes>
            {
              routes.map((item, i) => {
                return (
                  <Route key={i} path={item.path} element={
                    <Suspense >
                        {
                          item.path === '/'? <Navigate  to={item.redirect === undefined ? '': item.redirect } /> : < item.element />
                        }
                      </Suspense>
                    } />
                  
                )
              })
            }
        </Routes>
        {/* <Mask visible={isPaymentLoading} onMaskClick={() => {}} >
          <div className="overlayContent">
            <SpinLoading  style={{ '--size': '.8rem','--color':"#fff" }} />
              <div className="loading-text">正在领取</div>
            </div>
        </Mask> */}
        </Fragment>
    )
}

export default Layout 